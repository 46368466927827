import React, { useEffect } from "react";
import { Row, Container } from "reactstrap";
import Footer from "./Footer";

import { FaApple, FaGooglePlay } from "react-icons/fa";
import BannerImg from "./BannerImg";

import "./home.css";

function Home() {
  useEffect(() => {
    document.title = "CPIA - Home";
  });

  return (
    <Container className="home-padding">
      <Container>
        <Row className="text-center">
          <BannerImg />
        </Row>
      </Container>
      <Container className="text-center button-padding bottom-padding"></Container>

      <Container className="text-center jumbotron">
        <p>Manage your store on the go in the app</p>
        <Container>
          <Row>
            <Container className="text-center">
              <button className="btn btn-primary button-groups button-font-size">
                <FaApple className="logo-size" /> App Store
              </button>
              <button className="btn btn-primary button-font-size">
                <FaGooglePlay className="logo-size" /> Play Store
              </button>
            </Container>
          </Row>
        </Container>
      </Container>
      <Footer />
    </Container>
  );
}

export default Home;
