import React, { useState } from "react";
import axios from "axios";
import { Container } from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";

const CreateProduct = (props) => {
  document.title = "CPIA - New Product";
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [quantity, setQuantity] = useState("");
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [redirect, setRedirect] = useState(false);

  const handleDateChange = (date) => {
    setExpirationDate(date);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newProduct = {
      productName: productName,
      price: price,
      category: category,
      quantity: quantity,
      expirationDate: expirationDate,
    };
    console.log(newProduct);

    axios
      .post("https://dev-cpia-cloud.herokuapp.com/products/add", newProduct)
      .catch((error) => console.error(error))
      .then((res) => console.log(res.data));

    setRedirect(true);
  };

  const { from } = "/products/add";

  return (
    <Container>
      <div className="products-padding-top">
        <h3 className="text-center">Create New Product</h3>
        <div className="container-fluid add-prod-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label> Product Name: </label>
              <input
                type="text"
                required
                className="form-control input-width"
                onChange={(e) => setProductName(e.target.value)}
                value={productName}
              />
            </div>
            <div className="form-group">
              <label>Price:</label>
              <NumberFormat
                required
                className="form-control input-width"
                onChange={(e) => setPrice(e.target.value)}
                thousandSeparator={false}
                value={price}
              />
            </div>
            <div className="form-group">
              <label>Product Category:</label>
              <select
                type="text"
                required
                className="form-control input-width"
                onChange={(e) => setCategory(e.target.value)}
                value={category}
              >
                <option>Medicine</option>
                <option>Galenicals</option>
                <option>Miscellaneous/Others</option>
              </select>
            </div>
            <div className="form-group">
              <label>Quantity:</label>
              <NumberFormat
                required
                className="form-control input-width"
                onChange={(e) => setQuantity(e.target.value)}
                value={quantity}
              />
            </div>
            <div>
              <label>Expiration Date: </label>
              <br />
              <DatePicker
                className="form-control"
                selected={expirationDate}
                onChange={handleDateChange}
                value={expirationDate}
              />
            </div>
            <div className="row add-prod-btn-row text-center">
              <div className="add-prod-btn-separator">
                <div className="form-group text-center products-create-button">
                  <input
                    type="submit"
                    value="Add Product"
                    className="btn btn-primary"
                  />
                </div>
              </div>
              <div>
                <div className="form-group text-center products-create-button">
                  <Link to="/products">
                    <input
                      type="cancel"
                      value="Cancel"
                      className="btn btn-primary"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </form>
          {redirect && <Redirect to={from || "/products"} />}
        </div>
      </div>
    </Container>
  );
};

export default CreateProduct;
