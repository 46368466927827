import React, { useState } from "react";
import { Container, Jumbotron } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import "./auth.css";
import MailTwoToneIcon from "@material-ui/icons/MailTwoTone";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import { render } from "react-dom";

require("dotenv");

const CPIA_AUTH_SERVER = process.env.CPIA_AUTH_SERVER;

function SignUpAlert() {
  return (
    <Container className="login-alert">
      <div className="alert alert-dismissible alert-primary">
        <button type="button" class="close" data-dismiss="alert">
          &times;
        </button>
        <strong>No account yet? </strong>
        <Link to="/signup" className="alert-link">
          Sign up
        </Link>{" "}
        now.
      </div>
    </Container>
  );
}

function LoginForm() {
  const [email, setEmail] = useState([""]);
  const [password, setPassword] = useState([""]);
  const [data, setData] = useState([""]);
  const [auth, setAuth] = useState([false]);

  function handleSubmission(e, res, req) {
    e.preventDefault();
    axios({
      method: "post",
      url: CPIA_AUTH_SERVER,
      data: qs.stringify({
        email: email,
        password: password,
      }),
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => setData(res.data));
  }

  return (
    <Container>
      <SignUpAlert />
      <form onSubmit={handleSubmission}>
        <h1>Login</h1>
        <div className="form-group">
          <MailTwoToneIcon />
          <label className="col-form-label">Email address:</label>
          <input
            className="form-control"
            type="email"
            placeholder="youremail@cpia.com"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <LockTwoToneIcon />
          <label className="col-form-label">Password:</label>
          <input
            className="form-control"
            type="password"
            placeholder="fully secured password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input className="btn btn-primary" type="submit" value="Login" />
        </div>
      </form>
    </Container>
  );
}
export default function Login() {
  return (
    <div classNameNameName="container login-page">
      <h1>Login page!</h1>
      <div className="form-padding">
        <LoginForm />
      </div>
    </div>
  );
}
