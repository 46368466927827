import React, { useEffect } from "react";

function Reports() {
  useEffect(() => {
    document.title = "CPIA - Reports";
  });

  return (
    <div className="jumbotron reports-padding">
      <h1 className="text-center">This is reports</h1>
    </div>
  );
}

export default Reports;
