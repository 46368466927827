import React from "react";

import { Container, Form, FormGroup, Input } from "reactstrap";
import ProductsList from "./ProductsList";

import { Link } from "react-router-dom";

import "./products.css";

class SearchBar extends React.Component {
  render() {
    return (
      <div className="container-fluid prod-padding">
        <Form className="searchForm">
          <FormGroup className="inline-display">
            <Input
              type="text"
              placeholder="Search"
              className="form-control mr-sm-2"
            />
            <Input
              type="submit"
              value="Search"
              className="btn btn-primary search-auto-width"
            />
          </FormGroup>
        </Form>
      </div>
    );
  }
}

const AddProductButton = () => (
  <Link to="/products/add" id="white-font">
    <button className="btn btn-primary prod-margin" id="add-prod">
      Add Product
    </button>
  </Link>
);

class Products extends React.Component {
  componentDidMount() {
    document.title = "CPIA -  Products";
  }
  render() {
    return (
      <div>
        <Container fluid>
          <SearchBar />
          <AddProductButton />
          <ProductsList />
        </Container>
      </div>
    );
  }
}
export default Products;
