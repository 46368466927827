import React from "react";
import SocialMedia from "./SocialMedia";
import { Container } from "reactstrap";

const Footer = () => {
  return (
    <Container>
      <footer className="text-center">
        <p>Copyright {new Date().getFullYear()} | CPIA Cloud</p>
        <SocialMedia />
      </footer>
    </Container>
  );
};

export default Footer;
