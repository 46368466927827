import React, { Component } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";

const productsEndPoint = "https://dev-cpia-cloud.herokuapp.com/products/";

export default class EditProduct extends Component {
  constructor(props) {
    super(props);

    this.onChangeProductname = this.onChangeProductname.bind(this);
    this.onChangePrice = this.onChangePrice.bind(this);
    this.onChangeCategory = this.onChangeCategory.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);
    this.onChangeExpirationDate = this.onChangeExpirationDate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      productName: "",
      price: "",
      category: "",
      quantity: "",
      expirationDate: new Date(),
    };
  }

  componentDidMount() {
    axios
      .get(productsEndPoint + this.props.match.params.id)
      .then((res) => {
        this.setState({
          productName: res.data.productName,
          price: res.data.price,
          quantity: res.data.quantity,
          category: res.data.category,
          expirationDate: new Date(res.data.expirationDate),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onChangeProductname(e) {
    this.setState({
      productName: e.target.value,
    });
  }

  onChangePrice(e) {
    this.setState({
      price: e.target.value,
    });
  }

  onChangeCategory(e) {
    this.setState({
      category: e.target.value,
    });
  }

  onChangeQuantity(e) {
    this.setState({
      quantity: e.target.value,
    });
  }

  onChangeExpirationDate(expirationDate) {
    this.setState({
      expirationDate: expirationDate,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const product = {
      productName: this.state.productName,
      price: this.state.price,
      category: this.state.category,
      quantity: this.state.quantity,
      expirationDate: this.state.expirationDate,
    };
    console.log(product);

    axios
      .post(productsEndPoint + "/update/" + this.props.match.params.id, product)
      .then((res) => console.log(res.data));

    window.location = "/products";
  }

  render() {
    document.title = "CPIA -  " + this.state.productName;
    return (
      <Container>
        <div className="products-padding-top">
          <h3 className="text-center">
            Edit Product - {this.state.productName}
          </h3>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label> Product Name: </label>
              <input
                type="text"
                required
                className="form-control input-width"
                onChange={this.onChangeProductname}
                value={this.state.productName}
              />
            </div>
            <div className="form-group">
              <label>Price:</label>
              <NumberFormat
                required
                className="form-control input-width"
                onChange={this.onChangePrice}
                thousandSeparator={false}
                value={this.state.price}
              />
            </div>
            <div className="form-group">
              <label>Product Category:</label>
              <select
                type="text"
                required
                className="form-control input-width"
                onChange={this.onChangeCategory}
                value={this.state.category}
              >
                <option>Medicine</option>
                <option>Galenicals</option>
                <option>Miscellaneous/Others</option>
              </select>
            </div>
            <div className="form-group">
              <label>Quantity:</label>
              <NumberFormat
                required
                className="form-control input-width"
                onChange={this.onChangeQuantity}
                value={this.state.quantity}
              />
            </div>
            <div>
              <label>Expiration Date: </label>
              <br />
              <DatePicker
                className="form-control"
                selected={this.state.expirationDate}
                onChange={this.onChangeExpirationDate}
              />
            </div>
            <div className="row add-prod-btn-row container-fluid text-center">
              <div className="add-prod-btn-separator">
                <div className="form-group text-center products-create-button">
                  <input
                    type="submit"
                    value="Save"
                    className="btn btn-primary"
                  />
                </div>
              </div>
              <div>
                <div className="form-group text-center products-cancel-button">
                  <Link to="/products">
                    <input
                      type="cancel"
                      value="Cancel"
                      className="btn btn-primary"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Container>
    );
  }
}
