import React, { Component } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import NumberFormat from "react-number-format";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

const productEndpoint = " https://dev-cpia-cloud.herokuapp.com/products/";

const Products = (props) => (
  <Tr>
    <Td>{props.product.productName}</Td>
    <Td>
      <NumberFormat
        value={props.product.price}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"PHP "}
      />
    </Td>
    <Td>{props.product.category}</Td>
    <Td>
      <NumberFormat
        value={props.product.quantity}
        displayType={"text"}
        thousandSeparator={true}
      />
    </Td>
    <Td>{props.product.expirationDate.substring(0, 10)}</Td>
    <Td>
      <Link to={"/update/" + props.product._id}>
        <Button className="btn btn-info">EDIT</Button>
      </Link>
      <Link
        to={"/products"}
        onClick={() => {
          props.deleteProduct(props.product._id);
        }}
      >
        <Button className="btn btn-danger">DELETE</Button>
      </Link>
    </Td>
  </Tr>
);

export default class ProductsList extends Component {
  constructor(props) {
    super(props);

    this.deleteProduct = this.deleteProduct.bind(this);

    this.state = {
      products: [],
    };
  }
  // Fetch data from the database
  componentDidMount() {
    axios
      .get(productEndpoint)
      .then((response) => {
        this.setState({
          products: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteProduct(id) {
    axios
      .delete(productEndpoint + id)
      .then((response) => console.log(response.data));

    this.setState({
      products: this.state.products.filter(
        (productList) => productList._id !== id
      ),
    });
  }

  productList() {
    return this.state.products.map((currentProduct) => {
      return (
        <Products
          product={currentProduct}
          deleteProduct={this.deleteProduct}
          key={currentProduct._id}
        />
      );
    });
  }

  render() {
    return (
      <div className="container-fluid" id="table-padding">
        <h1>All Products</h1>
        <Table className="table">
          <Thead className="thead-light">
            <Tr>
              <Th>Product name</Th>
              <Th>Price</Th>
              <Th>Category</Th>
              <Th>Avaialable Stock</Th>
              <Th>Expiration Date</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>{this.productList()}</Tbody>
        </Table>
      </div>
    );
  }
}
