import React, { useState } from "react";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";

import { Link } from "react-router-dom";

const storeName = process.env.STORE_NAME || "AnaCon";

const AppNav = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNav = () => setCollapsed(!collapsed);

  return (
    <div id="fx-nav">
      <Navbar
        id="fx-nav"
        className="navbar navbar-expand-lg navbar-dark bg-dark navbar-fixed-top"
      >
        <Link href="/" className="store-name navbar-brand nav-link" to={"/"}>
          CPIA | {storeName}
        </Link>
        <NavbarToggler onClick={toggleNav} className="mr-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav className="ml-auto" id="nav-font" navbar>
            <Link to={"/dashboard"} id="nav-font" onClick={toggleNav}>
              <NavItem id="#nav-font">Dashboard</NavItem>
            </Link>
            <Link to={"/products"} id="nav-font" onClick={toggleNav}>
              <NavItem>Products</NavItem>
            </Link>
            <Link to={"/reports"} id="nav-font" onClick={toggleNav}>
              <NavItem>Reports</NavItem>
            </Link>
            <Link to={"/login"} id="nav-font" onClick={toggleNav}>
              <NavItem>Login</NavItem>
            </Link>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default AppNav;
