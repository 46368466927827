import React from "react";
import ApexChart from "./DonutChart";
import "./dashboard.css";

class Dashboard extends React.Component {
  componentDidMount() {
    document.title = "CPIA - Dashboard";
  }
  render() {
    return (
      <div className="dashboard-padding-top">
        <ApexChart />
      </div>
    );
  }
}

export default Dashboard;
