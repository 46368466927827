import React from "react";

import AppBanner from "./img/AppBanner.jpg";
import { Container, Jumbotron } from "reactstrap";
import LazyHero from "react-lazy-hero";

function BannerImg() {
  return (
    <Container fluid={true}>
      <LazyHero
        imageSrc={AppBanner}
        minHeight={"75vh"}
        parallaxOffset={100}
        transitionDuration={200}
        opacity={0.7}
        color={"#d4d0cf"}
      >
        <h1 className="home-header">We bring Pharma to the cloud</h1>
      </LazyHero>
    </Container>
  );
}

export default BannerImg;
