import React from "react";
import { Container } from "reactstrap";
import { SocialMediaIconsReact } from "social-media-icons-react";

const SocialMedia = () => {
  return (
    <Container>
      <SocialMediaIconsReact
        className="media-buttons"
        borderColor="rgba(0,0,0,0.25)"
        borderWidth="5"
        borderStyle="solid"
        icon="twitter"
        iconColor="rgba(235,226,226,1)"
        backgroundColor="rgba(7,7,7,1)"
        iconSize="5"
        roundness="0%"
        url="https://twitter.com"
        size="45"
      />
      <SocialMediaIconsReact
        className="media-buttons"
        borderColor="rgba(0,0,0,0.25)"
        borderWidth="5"
        borderStyle="solid"
        icon="facebook"
        iconColor="rgba(235,226,226,1)"
        backgroundColor="rgba(7,7,7,1)"
        iconSize="5"
        roundness="0%"
        url="https://facebook.com"
        size="45"
      />
      <SocialMediaIconsReact
        className="media-buttons"
        borderColor="rgba(0,0,0,0.25)"
        borderWidth="5"
        borderStyle="solid"
        icon="instagram"
        iconColor="rgba(235,226,226,1)"
        backgroundColor="rgba(7,7,7,1)"
        iconSize="5"
        roundness="0%"
        url="https://instagram.com"
        size="45"
      />
    </Container>
  );
};

export default SocialMedia;
