import React from "react";
import AppNav from "./components/AppNav";

import Products from "./components/pages/products/Products";
import Reports from "./components/pages/reports/Reports";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Home from "./components/pages/home/Home";
import CreateProduct from "./components/pages/products/CreateProduct";
import EditProduct from "./components/pages/products/EditProduct";
import Login from "./components/pages/auth/Login";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <AppNav />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/products" exact component={Products} />
        <Route path="/products/add" exact component={CreateProduct} />
        <Route path="/update/:id" exact component={EditProduct} />
        <Route path="/reports" component={Reports} />
        <Route path="/login" component={Login} />
      </Switch>
    </Router>
  );
}

export default App;
