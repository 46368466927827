import React from "react";
import Chart from "react-apexcharts";
import { Container } from "reactstrap";

class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [44, 12, 24],
      options: {
        labels: ["Galenical", "Medicines", "Miscellaneous"],
        legend: {
          show: true,
          position: "bottom"
        },
        title: {
          text: "Product Categories",
          align: "center"
        }
      }
    };
  }

  render() {
    return (
      <Container>
        <div id="chart" className="donut">
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="donut"
          />
        </div>
      </Container>
    );
  }
}

export default ApexChart;
